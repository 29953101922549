frappe.provide('facturacom.cfdi');


$.extend(facturacom.cfdi, {
    cancelCFDIPrmt: async function cancelCFDIPrmt(frm) {
        await new Promise((res, rej) => {
            let reason_code = ""
            const d = new frappe.ui.Dialog({
                title: 'Completa la información para continuar la cancelación de la factura',
                fields: facturacom.cfdi.getCancelPromtFields(frm, set_desc),
                size: 'small', // small, large, extra-large ,
                primary_action_label: 'Entendido',
                primary_action(values) {
    
                    d.hide()
                    frappe.show_progress('Cancelando CFDI...', 20, 100, 'Por favor espera', true);
                    if (reason_code !== "01") {
                        //Cancel right now
                        frappe.call({
                            method: "facturacom.custom.invoice.controller.cancel_invoice",
                            args: {
                                prev_invoice_name: frm.doc.name,
                                doctype: frm.doctype
                            },
                            callback: (r) => frm.reload_doc().then(() => {
                                frappe.show_progress('Cancelando CFDI...', 100, 100, 'Por favor espera', true);
                                setTimeout(() => {
                                    if (r.message === "sat not available") {
                                        frappe.show_alert({
                                            message: __('Ocurrió un error al cancelar'),
                                            indicator: 'red'
                                        }, 5);
                                    } else {
                                        frappe.show_alert({
                                            message: __('La factura se ha cancelado de manera correcta'),
                                            indicator: 'green'
                                        }, 5);
                                    }
    
                                    res()
                                }, 3000)
                            })
                        })
                    } else {
                        //Just hide
                        frappe.call({
                            method: "facturacom.custom.invoice.controller.temp_cancel_cfdi",
                            args: {
                                doc_name: frm.doc.name,
                                doctype: frm.doctype
                            },
                            callback: (r) => {
                                frappe.show_progress('Cancelando CFDI...', 100, 100, 'Por favor espera', true);
                                res()
                            }
                        })
                    }
    
    
    
                },
                secondary_action_label: 'Regresar',
                secondary_action(values) {
                    d.hide()
                    rej()
    
                },
            })
    
            async function set_desc(value) {
                if (!!value) {
                    doc = await frappe.db.get_doc("CFDI Motivos Cancelacion", value)
                    reason_code = doc.sat_code
                    d.set_input("cancelation_reason", doc.description)
                    d.set_input("next_steps", doc.next_steps)
                    d.set_df_property("important_message", "hidden", reason_code !== "01")
                    // d.fields_dict['important_message'].$wrapper.css('color', '#eb1010')
                    await new Promise((res2, rej2) => {
                        return frappe.db.set_value(frm.doctype, frm.docname, "custom_motivo_cancelacion", value, () => {
                            res2()
                        })
                    })
                    if (reason_code === "01") {
                        document.querySelector("body > div.modal.fade.show > div > div > div.modal-body.ui-front > div:nth-child(1) > div > div.form-page > div > div > div > form > div:nth-child(4) > div > div.control-input-wrapper > div.control-value.like-disabled-input.for-description.bold").style.color = 'red'
                    }
                }
            }
            d.show()
        })
        frm.refresh()
        return
    
    
    },
    initCancelMethods:function initCancelMethods(frm) {
        if (!!frm.doc.amended_from && frm.is_new() === 1) {
            frm.clear_table("custom_cfdis_relacionados")
            frm.add_child('custom_cfdis_relacionados', {
                relation_type: "Sustitución de los CFDI previos",
                linked_invoices: frm.doc.amended_from
            });
            frm.set_value({
                custom_estatus_de_facturacion: undefined,
                custom_folio: undefined,
                custom_uid_cfdi: undefined,
                
    
            }).then(() => frm.refresh_field('custom_cfdis_relacionados'));
    
            frm.set_df_property("custom_crear_cfdi","read_only",0)
            frm.set_df_property("custom_correo_facturacom","read_only",0)
    
            // frm.refresh_field('items');
    
    
        }
    
        if (frm.doc.custom_estatus_de_facturacion === "CFDI Emitido y Pendiente de Cancelar") {
            frm.add_custom_button(__('Cancelar Factura (CFDI)'), function () {
                frappe.show_progress('Cancelando CFDI...', 20, 100, 'Por favor espera', true);
                frappe.call({
                    method: "facturacom.custom.invoice.controller.retry_invoice_cancelation",
                    args: {
                        doc: frm.doc,
                    },
                    callback: (r) => {
                        frappe.show_progress('Cancelando CFDI...', 20, 100, 'Por favor espera', true);
                        if (r.message === "sat not available") {
                            frappe.show_alert({
                                message: __('Ocurrió un error al cancelar'),
                                indicator: 'red'
                            }, 5);
                        } else {
                            frappe.show_alert({
                                message: __('La factura se ha cancelado de manera correcta'),
                                indicator: 'green'
                            }, 5);
                        }
                    },
                    error: () => {
                        frappe.show_progress('Cancelando CFDI...', 100, 100, 'Por favor espera', true);
                    }
                })
            }, __("CFDI"));
    
        }
    
    },

    generateCFDIPrmt: async function generateCFDIPrmt(frm) {
        if (frm.doc.custom_crear_cfdi === 1) {
            return new Promise((res, rej) => frappe.confirm('Confirmas que deseas crear un CFDI para esta transacción?',
                () => {
                    frappe.show_progress('Timbrando factura', 20, 100, 'Por favor espera', true);
                    return frappe.call({
                        //frappe-bench/apps/facturacom/facturacom/custom/invoice/controller.py
                        method: "facturacom.custom.invoice.controller.create_invoice",
                        args: {
                            docname: frm.docname,
                            doctype:frm.doctype 
                        },
                        callback: () => frm.reload_doc().then(() => {
    
                            if (!!frm.doc.amended_from) {
                                //There is a previous document, prev doc should be cancelled
                                frappe.show_progress('Timbrando factura', 50, 100, 'Por favor espera', true);
                                return frappe.call({
                                    method: "facturacom.custom.invoice.controller.cancel_invoice",
                                    args: {
                                        prev_invoice_name: frm.doc.amended_from,
                                        doctype: frm.doctype,
                                        new_invoice_name: frm.doc.name
                                    },
                                    callback: (r) => {
                                        frappe.show_progress('Timbrando factura', 100, 100, 'Por favor espera', true);
    
                                        setTimeout(() => {
                                            if (r.message === "sat not available") {
                                                frappe.show_alert({
                                                    message: __('Ocurrió un error al cancelar la factura anterior'),
                                                    indicator: 'red'
                                                }, 5);
                                            } else {
                                                frappe.show_alert({
                                                    message: __('La factura se ha cancelado de manera correcta'),
                                                    indicator: 'green'
                                                }, 5);
                                            }
    
                                            res()
                                        }, 3000)
    
                                    },
                                    error: () => {
                                        frappe.show_progress('Timbrando factura', 100, 100, 'Por favor espera', true);
                                        return frm.refresh().then(() => rej())
                                    }
                                })
    
                            } else {
                                frappe.show_progress('Timbrando factura', 100, 100, 'Por favor espera', true);
                                res()
    
                            }
    
                        })
                        ,
                        error: () => {
                            frappe.show_progress('Timbrando factura', 100, 100, 'Por favor espera', true);
                        }
                    });
                }
                , () => {
                    rej()
                }))
        } else {
            return
        }
    
    
    
    }

})

