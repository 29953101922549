frappe.provide('facturacom.cfdi');

$.extend(facturacom.cfdi, {
    getCancelPromtFields: function getCancelPromtFields(frm, cb) {
        return [{
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            onchange: function setMotive(params) {
                cb(this.value)
            },
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "default": null,
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "cancelation_motive",
            "fieldtype": "Link",
            "hidden": 0,
            "hide_border": 0,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 1,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Motivo de cancelación",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "module": null,
            "no_copy": 0,
            "non_negative": 0,
            "options": "CFDI Motivos Cancelacion",
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 0,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 1,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        }, {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "default": null,
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "cancelation_reason",
            "fieldtype": "Text",
            "hidden": 0,
            "hide_border": 1,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 1,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Motivo de cancelación",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "module": null,
            "no_copy": 0,
            "non_negative": 0,
            "options": null,
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 1,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 0,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        },
        {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "default": null,
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "next_steps",
            "fieldtype": "Text",
            "hidden": 0,
            "hide_border": 1,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 1,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Siguientes pasos",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "module": null,
            "no_copy": 0,
            "non_negative": 0,
            "options": null,
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 1,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 0,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        },
        {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 1,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "default": "Al seleccionar este motivo, la factura se cancelará hasta que edites y factures la nueva versión; de lo contrario, seguirá siendo válida.",
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "important_message",
            "fieldtype": "Text",
            "hidden": 1,
            "hide_border": 1,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 3,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "IMPORTANTE",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "module": null,
            "no_copy": 0,
            "non_negative": 0,
            "options": null,
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 1,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 0,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        }]
    },
    getPromptFields: function getPromptFields(frm) {

        return [{
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "creation": "2024-07-30 00:11:16.950574",
            "default": "factura",
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "dt": "Sales Invoice",
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "custom_tipo_de_documento",
            "fieldtype": "Link",
            "hidden": 0,
            "hide_border": 0,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 64,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "insert_after": "custom_uso",
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Tipo de Documento",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "modified": "2024-07-30 00:11:16.950574",
            "modified_by": "Administrator",
            "module": null,
            "name": "Sales Invoice-custom_tipo_de_documento",
            "no_copy": 0,
            "non_negative": 0,
            "options": "Tipo CFDI",
            "owner": "Administrator",
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 0,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 1,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        }, {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "creation": "2024-07-30 00:15:10.283920",
            "default": frm.doc.custom_uso || null,
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "dt": "Sales Invoice",
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "custom_uso",
            "fieldtype": "Link",
            "hidden": 0,
            "hide_border": 0,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 63,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "insert_after": "custom_sat",
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Uso",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "modified": "2024-07-30 00:15:10.283920",
            "modified_by": "Administrator",
            "module": null,
            "name": "Sales Invoice-custom_uso",
            "no_copy": 0,
            "non_negative": 0,
            "options": "Uso de CFDI",
            "owner": "Administrator",
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 0,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 1,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        }, {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "creation": "2024-07-30 00:12:09.654303",
            "default": frm.doc.custom_serie || null,
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "dt": "Sales Invoice",
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "custom_serie",
            "fieldtype": "Link",
            "hidden": 0,
            "hide_border": 0,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 65,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "insert_after": "custom_tipo_de_documento",
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Serie",
            "length": 0,
            "link_filters": '[["Serie CFDI", "tipo_doc", "=", "factura"]]',
            "mandatory_depends_on": null,
            "modified": "2024-07-30 00:12:09.654303",
            "modified_by": "Administrator",
            "module": null,
            "name": "Sales Invoice-custom_serie",
            "no_copy": 0,
            "non_negative": 0,
            "options": "Serie CFDI",
            "owner": "Administrator",
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 0,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 1,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        }, {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "creation": "2024-07-30 20:23:53.215994",
            "default": frm.doc.custom_metodo_de_pago || null,
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "dt": "Sales Invoice",
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "custom_metodo_de_pago",
            "fieldtype": "Link",
            "hidden": 0,
            "hide_border": 0,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 68,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "insert_after": "custom_correo_facturacom",
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Froma de Pago",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "modified": "2024-07-30 20:23:53.215994",
            "modified_by": "Administrator",
            "module": null,
            "name": "Sales Invoice-custom_metodo_de_pago",
            "no_copy": 0,
            "non_negative": 0,
            "options": "Metodo de Pago CFDI",
            "owner": "Administrator",
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 0,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 1,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        }, {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "creation": "2024-07-29 14:47:08.994035",
            "default": "Pago en una sola exhibici\u00f3n - PUE",
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "dt": "Sales Invoice",
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "custom_forma_pago_cfdi",
            "fieldtype": "Select",
            "hidden": 0,
            "hide_border": 0,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 66,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "insert_after": "custom_serie",
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Metodo de Pago",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "modified": "2024-07-29 14:47:08.994035",
            "modified_by": "Administrator",
            "module": null,
            "name": "Sales Invoice-custom_forma_pago_cfdi",
            "no_copy": 0,
            "non_negative": 0,
            "options": "Pago en una sola exhibici\u00f3n - PUE\nPago en parcialidades o diferido - PPD",
            "owner": "Administrator",
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 0,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 1,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 1,
            "unique": 0,
            "width": null
        },
        {
            "_assign": null,
            "_comments": null,
            "_liked_by": null,
            "_user_tags": null,
            "allow_in_quick_entry": 0,
            "allow_on_submit": 0,
            "bold": 0,
            "collapsible": 0,
            "collapsible_depends_on": null,
            "columns": 0,
            "creation": "2024-07-29 14:48:53.373266",
            "default": frm.doc.custom_correo_facturacom || null,
            "depends_on": null,
            "description": null,
            "docstatus": 0,
            "dt": "Sales Invoice",
            "fetch_from": null,
            "fetch_if_empty": 0,
            "fieldname": "custom_correo_facturacom",
            "fieldtype": "Check",
            "hidden": 0,
            "hide_border": 0,
            "hide_days": 0,
            "hide_seconds": 0,
            "idx": 67,
            "ignore_user_permissions": 0,
            "ignore_xss_filter": 0,
            "in_global_search": 0,
            "in_list_view": 0,
            "in_preview": 0,
            "in_standard_filter": 0,
            "insert_after": "custom_forma_pago_cfdi",
            "is_system_generated": 0,
            "is_virtual": 0,
            "label": "Enviar correo de Factura.com?",
            "length": 0,
            "link_filters": null,
            "mandatory_depends_on": null,
            "modified": "2024-07-29 14:48:53.373266",
            "modified_by": "Administrator",
            "module": null,
            "name": "Sales Invoice-custom_correo_facturacom",
            "no_copy": 0,
            "non_negative": 0,
            "options": null,
            "owner": "Administrator",
            "permlevel": 0,
            "precision": "",
            "print_hide": 0,
            "print_hide_if_no_value": 0,
            "print_width": null,
            "read_only": 0,
            "read_only_depends_on": null,
            "report_hide": 0,
            "reqd": 1,
            "search_index": 0,
            "show_dashboard": 0,
            "sort_options": 0,
            "translatable": 0,
            "unique": 0,
            "width": null
        }]

    }
})




